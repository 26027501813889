import { NextPage } from 'next'
import { useRouter } from 'next/router'
import React from 'react'
import { PageContent } from '../modules/page-content'
import { MainLayout } from '../modules/app/main-layout'

const VitrinePage: NextPage = () => {
  const router = useRouter()
  const { page } = router.query

  return (
    <MainLayout>
      <PageContent pageUrl={page} />
    </MainLayout>
  )
}

export default VitrinePage
